// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
    return (
        <div className="not-found-container">
            <h1>404</h1>
            <p>Oops, this page doesn't exist.</p>
            <Link to="/">Back to homepage</Link>
        </div>
    );
};

export default NotFound;
