// App.js
import React from "react";
import "./Home.css";

function App() {
  return (
    <div className="container">
      <div className="centered-image">
        <img src={`${process.env.PUBLIC_URL}/transparent-background-chick.webp`} alt="fiaschetta" />
      </div>
    </div>
  );
}

export default App;
